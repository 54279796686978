(function ($) {
    var objBrowserGeolocation = {
        lat: '',
        lng: '',
        city: '',
        state: '',
        region: '',

        $location: null,
        $city: null,
        $state: null,

        init: function () {
            objBrowserGeolocation.getLocation();
            objBrowserGeolocation.$location = $('input[name="location"]');
            objBrowserGeolocation.$city = $('input[name="info[Build City]"]');
            objBrowserGeolocation.$state = $('input[name="info[Build State]"]');
        },

        getLocation: function () {
            navigator.geolocation.getCurrentPosition(objBrowserGeolocation.locationSuccess, objBrowserGeolocation.locationFailure);
        },

        translateLocationFromLongLat: function () {
            objBrowserGeolocation.disabledInputs(true);
            $.get('/find-lat-lon', {
                'lat': objBrowserGeolocation.lat,
                'lon': objBrowserGeolocation.lng
            }).done(function (resultData) {
                if (resultData) {
                    objBrowserGeolocation.city = resultData.city;
                    objBrowserGeolocation.state = resultData.state;
                    objBrowserGeolocation.region = resultData.region;
                    objBrowserGeolocation.populateInput();
                } else {
                    objBrowserGeolocation.locationFailure();
                }
            }).always(function () {
                objBrowserGeolocation.disabledInputs(false);
            });
        },

        populateInput: function () {
            if (objBrowserGeolocation.$location.val() === '') {
                switch (objBrowserGeolocation.$location.data('search-type')) {
                    case 'state-only':
                        objBrowserGeolocation.$location.val(objBrowserGeolocation.region + ' (' + objBrowserGeolocation.state + ')');
                        break;
                    default:
                        objBrowserGeolocation.$location.val(objBrowserGeolocation.city + ', ' + objBrowserGeolocation.state);
                        break;
                }
            }
            if (objBrowserGeolocation.$city.val() === '' && objBrowserGeolocation.$state.val() === '') {
                objBrowserGeolocation.$city.val(objBrowserGeolocation.city);
                objBrowserGeolocation.$state.val(objBrowserGeolocation.region + ' (' + objBrowserGeolocation.state + ')');
            }
        },

        locationSuccess: function (position) {
            objBrowserGeolocation.lat = position.coords.latitude;
            objBrowserGeolocation.lng = position.coords.longitude;
            objBrowserGeolocation.translateLocationFromLongLat();
        },

        locationFailure: function () {
            if (objBrowserGeolocation.$location.val() === '' && objBrowserGeolocation.$location.data('ip-location')) {
                objBrowserGeolocation.$location.val(objBrowserGeolocation.$location.data('ip-location'));
            }
        },

        disabledInputs: function (disabled) {
            objBrowserGeolocation.$location.prop('disabled', disabled);
            objBrowserGeolocation.$city.prop('disabled', disabled);
            objBrowserGeolocation.$state.prop('disabled', disabled);
        }
    }

    $(document).ready(function () {
        if (navigator.geolocation) {
            objBrowserGeolocation.init();
        }
    });
}(jQuery));
