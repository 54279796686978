var UTIL = UTIL || {};

(function ($) {

	UTIL.scrollto = function (selector, animate) {
	    var $scrollto = $(selector),
	        animate = !! animate,
	        scrollto;
	    
	    if ($scrollto.length > 0) {
	    	scrollto = $scrollto.offset().top;

	        $('[data-util-scrollto-offset]').each(function () {
	            scrollto -= $(this).outerHeight(true);
	        });

	        if (animate) {
	        	$('html, body').animate({scrollTop: scrollto}, 250, 'swing');
	        } else {
	        	window.scrollTo(0, scrollto);
	        }
	    }
	};
}(jQuery));