(function ($) {
    $(function () {
        if ( ! $('.configurator__option').length) return;

        var $message,
            additionalDetails = {
                sqft: 0,
                bedrooms: 0,
                bathrooms: 0,
                floorplansPrice: 0,
                elevationsPrice: 0,
            },
            searchParams = new URLSearchParams(location.search),
            $modelPriceTotal = $('[data-configurator-model-price-total]'),
            $formConfigurationLink = $('input[name="info[Configuration Link]"]');

        function configuratorOptionOnChange() {
            var $option = $(this),
                $options = $('.configurator__option[name="' + $option.attr('name') + '"]'),
                $optionItems = $('.configurator__option-item'),
                $elevation = $('.configurator__option[value="' + $option.data('configurator-elevation') + '"]'),
                // $elevationNotes = $('#configurator__force-elevation-notes'),
                $detailsFormat = $('[data-configurator-details-format]'),
                originalDetails = $detailsFormat.data('configurator-details'),
                modelPrice = $('[data-configurator-model-price]').data('configurator-model-price'),
                $modelPriceFloorplan = $('[data-configurator-model-price-floorplan]'),
                $modelPriceElevation = $('[data-configurator-model-price-elevation]');

            additionalDetails.sqft = null;
            additionalDetails.bedrooms = null;
            additionalDetails.bathrooms = null;
            additionalDetails.floorplansPrice = 0;
            additionalDetails.elevationsPrice = 0;

            $elevation.not(':checked').prop('checked', true).each(configuratorOptionToggleImages);
            $options.each(configuratorOptionUncheckIfForced).each(configuratorOptionToggleImage);

            $('.configurator__option:checked').each(configuratorOptionItemsAdditionalDetails);

            $detailsFormat.text(
                $detailsFormat.data('configurator-details-format')
                    .replace('%1$s', new Intl.NumberFormat().format(originalDetails.sqft + additionalDetails.sqft))
                    .replace('%2$s', originalDetails.bedrooms + additionalDetails.bedrooms)
                    .replace('%3$s', originalDetails.bathrooms + additionalDetails.bathrooms)
            );
            $modelPriceFloorplan.text(new Intl.NumberFormat().format(additionalDetails.floorplansPrice));
            $modelPriceElevation.text(new Intl.NumberFormat().format(additionalDetails.elevationsPrice));
            $modelPriceTotal.text(new Intl.NumberFormat().format(modelPrice + additionalDetails.floorplansPrice + additionalDetails.elevationsPrice));

            $message && $message.trigger('message-dismiss');

            if ($elevation.length > 0 && $option.data('configurator-elevation-notes')) {
                $message = CHWEB.message($option.data('configurator-elevation-notes'), 'warning');
            }

            if ($option.is('[value^="floorplan:"]')) {
                var check = {};
                var query = [];

                $('[value^="floorplan:"]').each(function () {
                    var $this = $(this);
                    var group = $this.attr('name').replace(/floorplan\[(.+)\]\[\]/, '$1');
                    var value = $this.val().split(':').reverse()[0];

                    if ($this.is(':checked')) {
                        (check[group] = check[group] || []).push(value);
                    }
                })

                for (var group in check) {
                    query.push([group, check[group].join('+')].join(':'));
                }

                query.length ? searchParams.set('floorplans', query.join(',')) : searchParams.delete('floorplans');
            }

            if ($option.is('[value^="elevation:"]:checked')) {
                var value = $option.val().split(':').reverse()[0];

                value ? searchParams.set('elevations', value) : searchParams.delete('elevations');
            }

            var configurationPrice = $modelPriceTotal.parent().text();

            searchParams.delete('price');

            history.replaceState({}, '', (location.pathname+'?'+decodeURIComponent(searchParams)).replace(/\?$/, ''));
            configurationPrice && searchParams.set('price', configurationPrice);
            $formConfigurationLink.val((location.protocol+'//'+ location.host+location.pathname+'?'+decodeURIComponent(searchParams)).replace(/\?$/, ''));
        }
        
        function configuratorOptionItemsAdditionalDetails() {
            var $this = $(this),
                details = $this.data('configurator-details-additional');

            if ( ! details) return;
            
            if (details.sqft && (details.sqft > additionalDetails.sqft || ! additionalDetails.sqft)) {
                additionalDetails.sqft = details.sqft;
            }
            if (details.bedrooms && (details.bedrooms > additionalDetails.bedrooms || ! additionalDetails.bedrooms)) {
                additionalDetails.bedrooms = details.bedrooms;
            }
            if (details.bathrooms && (details.bathrooms > additionalDetails.bathrooms || ! additionalDetails.bathrooms)) {
                additionalDetails.bathrooms = details.bathrooms;
            }
            if (details.price) {
                if ($this.attr('name') === 'elevation') {
                    additionalDetails.elevationsPrice += details.price;
                } else {
                    additionalDetails.floorplansPrice += details.price;
                }
            }
        }
        
        function configuratorOptionUncheckIfForced() {
            var $option = $(this),
                $floorplan = $('.configurator__option[data-configurator-elevation]');

            if ( ! $option.is(':checked')) {
                $floorplan
                    .filter('[data-configurator-elevation="' + $option.val() + '"]')
                    .prop('checked', false)
                    .each(configuratorOptionToggleImage);
            }
            else {
                $floorplan
                    .prop('checked', function () {
                        var $this = $(this),
                            $elevation = $('.configurator__option[value="' + $this.data('configurator-elevation') + '"]');
                        return $this.is(':checked') ? $elevation.is(':checked') : false;
                    })
                    .each(configuratorOptionEmptyCheckStandard)
                    .each(configuratorOptionToggleImage);
            }
        }

        function configuratorOptionEmptyCheckStandard() {
            var $option = $(this),
                $options = $('.configurator__option[name="' + $option.attr('name') + '"]');

            if ($options.filter(':checked').length === 0) {
                $options.filter('[value=""]').prop('checked', true);
            }
        }

        function configuratorOptionToggleImages() {
            var $option = $(this),
                $options = $('.configurator__option[name="' + $option.attr('name') + '"]');

            $options.each(configuratorOptionToggleImage);
        }

        function configuratorOptionToggleImage() {
            var $option = $(this);

            $('.configurator__image[data-configurator-option="' + $option.val() + '"]')
                .toggleClass('is-active', $option.is(':checked'));
        }



        $(document).on('change.configurator', '.configurator__option', configuratorOptionOnChange);
        $('.configurator__option:checked').trigger('change.configurator');

    });
}(jQuery));
