(function ($) {
    
    function scrollNavDocumentHeightChanged() {
        $('#scroll-nav').scrollNav('destroy');
        scrollNavInit();
    }
    
    function scrollNavInit() {
        $('#scroll-nav').scrollNav({
            fixedMargin: 0,
            insertLocation: 'prependTo',
            insertTarget: '#scroll-nav__nav',
            scrollOffset: $('#scroll-nav__bar').outerHeight(true),
            showHeadline: false,
            showTopLink: false,
            speed: 350,
        });
    }
    
    
    
    $(window).on('load.scroll-nav', scrollNavDocumentHeightChanged);
    $(document).on('document-height-changed', scrollNavDocumentHeightChanged);
    
    $(function () {
        scrollNavInit();
    });
}(jQuery));