(function ($) {

    function activateDropdown() {
        $(this)
            .closest('.dropdown')
            .find('.dropdown__body')
            .removeClass('is-hidden')
            .end()
            .delay(10)
            .queue(function (next) {
                $(this).toggleClass('is-active');
                next();
            });
    }

    function toggleDropdown(event) {
        $(this).toggleClass('is-hidden', ! $(this).closest('.dropdown').hasClass('is-active'));
    }

    $(document)
        .on('click.dropdown', '.dropdown__head', activateDropdown)
        .on('transitionend.dropdown', '.dropdown__body', toggleDropdown);
        
}(jQuery));
