(function ($) {
    function tabsHeadOnClick() {
        var $this = $(this),
            $body = $('#' + $this.attr('aria-controls')),
            $tabs = $this.closest('.tabs'),
            height;

        $tabs
            .find('.tabs__head.is-active, .tabs__body[aria-expanded="true"]')
            .filter(function () {
                return $(this).closest('.tabs')[0] === $tabs[0];
            })
            .filter('.tabs__body')
            .attr('aria-expanded', 'false')
            .each(function () {
                // we need to track the height of the current body
                height = $(this).outerHeight(true);
            })
            .end()
            .removeClass('is-active')
            .end()
            .end()
            .find('[aria-controls="' + $this.attr('aria-controls') + '"]')
            .addClass('is-active');

        $body
            .attr('aria-expanded', 'true')
            .addClass('is-active')
            .each(function () {
                if ($(this).outerHeight(true) !== height) {
                    // the height of the current body is different than the one of the previously active one
                    $(document).trigger('document-height-changed');
                }
            });
    }

    $(document).on('click.tabs', '.tabs__head', tabsHeadOnClick);
}(jQuery));