(function ($) {
    
    var $message;
    
    function jsActionPrintOnClick(event) {
        window.print();
    }
    
    function jsActionSmoothScrollOnClick(event) {
        var $this = $(this),
            $that = $($this.attr('href'));
        
        if ($that.length > 0) {
            event.preventDefault();
            $('html,body').animate({ scrollTop: $that.offset().top }, "fast", "linear");
        }
    }
    
    
    $(document)
        .on('click.js-action', '[data-js-action-print]', jsActionPrintOnClick)
        .on('click.js-action', '[data-js-action-smooth-scroll][href]', jsActionSmoothScrollOnClick);
}(jQuery));
