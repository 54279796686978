(function ($) {
    var output = '';

    function telFormat(event) {
        var $this = $(this),
            input = $this.val(),
            ltr = input.length >= output.length; // direction of typing

        output = '';

        for (var i = 0, l = input.length; i < l; i++) {
            if (0 <= input[i] && input[i] <= 9 && input[i] !== ' ') {
                output += input[i];

                if ((output.length === 3 || output.length === 7) && (ltr || (! ltr && l > output.length))) {
                    output += '-';
                }
            }
        }

        $this.val(output);
    }

    $(document).on('keyup.tel', 'input[type="tel"]', telFormat);
}(jQuery));
