(function ($) {

    var xhr;

    function autocomplete () {
        var $this = $(this),
            menuClasses = ['form__autocomplete'];

        function source (term, suggest) {

            function done (response) {

                var result = [],
                    address,
                    l = response.length,
                    i;

                for (i = 0; i < l; i++) {
                    address = [];

                    response[i].city && address.push(response[i].city);
                    response[i].state && address.push(
                        ( response[i].state.name && response[i].state.code )
                        ? response[i].state.name + ' (' + response[i].state.code + ')'
                        : response[i].state
                    );
                    response[i].zip && address.push(response[i].zip);

                    result.push(address.join(', '));
                }

                suggest(result);
            }

            try { xhr.abort(); } catch(e) {}

            xhr = $.get($this.data('find-autocomplete'), { q: term }).done(done);
        }


        if ($this.hasClass('form__input--negative')) {
            menuClasses.push('form__autocomplete--negative');
            menuClasses.push('u-negative');
        }
        if ($this.hasClass('form__input--join--l')) {
            menuClasses.push('form__autocomplete--join--l');
        }
        if ($this.hasClass('form__input--join--r')) {
            menuClasses.push('form__autocomplete--join--r');
        }

        $this.autoComplete({
            source: source,
            menuClass: menuClasses.join(' '),
            onSelect: function(event, term, item) {
                $this
                .closest('form:not([novalidate])')
                .attr('novalidate', true)
                .delay(10)
                .queue(function (next) {
                    $(this).removeAttr('novalidate');
                    next();
                });
            }
        });
    }

    $(function () {
        $('[data-find-autocomplete]').each(autocomplete);
    });

}(jQuery));
