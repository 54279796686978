/**
 * @author Sergio Agosti <sagosti@theframeworks.com>
 * @requires jQuery 1.10.2+
 *
 * Enables the ability to animate any property even when the value is not defined or not numeric.
 * The element with ".has-transitions" will have the transitions defined.
 * The element with ".is-transitioned" gives one of the two statuses for the transitioning properties.
 *
 * <div class="dropdown">
 *    <p>Some copy here...</p>
 * </div>
 *
 * .dropdown {
 *     overflow: hidden;
 *     height: 0;
 * }
 * .dropdown.is-transitioned {
 *     height: auto;
 * }
 * .dropdown.has-transitions {
 *     transition: height 300ms;
 * }
 */
(function ($) {
    'use strict';

    $.fn.transitionCSS = function (params, callback) {

        var defaults = {
                hasTransitionsCSSClass: 'has-transitions',
                isTransitionerCSSClass: 'is-transitioned',
                changeStatusCB: function (options) {
                    
                    $(this).toggleClass(options.isTransitionerCSSClass);
                }
            },
            transitionEndEvent = ['transitionend', 'webkitTransitionEnd'];

        return this.each(function () {

            var $element = $(this),
                options = defaults;

            if (typeof params === 'object') {
                options = $.extend(options, params);
            }

            if (typeof params === 'string') {
                options.isTransitionerCSSClass = params;
            }

            if (typeof params === 'function') {
                callback = params;
            }

            (function () {

                var element = this,
                    properties = [],
                    oldProperties = {},
                    newProperties = {},
                    isTransitionNeeded = false,
                    i, newValue, oldValue, property;
                
                // add the class which defines the transition properties to the element
                $element.addClass(options.hasTransitionsCSSClass);
                
                // get the transition properties and save them into an array
                    // IE9 workaround, transition-property is not understood by the browser but transition is
                    // @see #2238
                if (typeof $element.css('transition-property') === 'undefined' && typeof $element.css('transition') !== 'undefined') {
                
                    properties = $element.css('transition').split(', ');
                }
                else if (typeof $element.css('transition-property') !== 'undefined') {
                
                    properties = $element.css('transition-property').split(', ');
                }
                
                // remove the class which defines the transition properties so we can get the
                // computed values for the properties after the transition would have happened
                $element.removeClass(options.hasTransitionsCSSClass);

                // get the computed values for the transition properties
                // and save them into the oldProperties array
                for (i = 0; i < properties.length; i += 1) {
                
                    oldProperties[properties[i]] = $element.css(properties[i]);
                }
                
                // change the status of the element
                options.changeStatusCB.apply(this, [options]);
                
                // get the computed values for the transition properties once the status has changed
                // and save them into the newProperties array
                for (property in oldProperties) {
                
                    if (oldProperties.hasOwnProperty(property)) {

                        newValue = $element.css(property);
                        oldValue = oldProperties.property;

                        if (oldValue !== newValue) {
                            
                            isTransitionNeeded = true;
                            newProperties[property] = newValue;
                        }
                        else {

                            delete(oldProperties.property);
                        }
                    }
                }

                if (isTransitionNeeded) {
                    
                    // apply the initial css values for the transition properties
                    $element.css(oldProperties);

                    // setTimeout needed to allow certain browsers to apply oldProperties
                    setTimeout(function () {
                        
                        // add the class to enable transitions and apply the final css values
                        // for the transition properties
                        $element
                            .addClass(options.hasTransitionsCSSClass)
                            .css(newProperties);
                        
                        // clear the css values injected on the element once the transition is completed
                        $element.one(transitionEndEvent.join(' '), function () {

                            // need to esplicitly 'off' this event to avoid double call
                            // when the browser supports both vendor-prefixed and standard event name
                            $element.off(transitionEndEvent.join(' ')).removeClass(options.hasTransitionsCSSClass);

                            $.each(newProperties, function (property, value) {

                                $element.css(property, '');
                            });

                            callback && callback.apply(element);
                        });

                    }, 10);
                }
                else {
                    callback && callback.apply(element);
                }

            }).apply(this);
        });
    };

}(jQuery));
