var CHWEB = CHWEB || {};
(function ($) {
    "use strict";
    
    $(function () {
        var selector = "[data-gmaps]",
            options = {
                zoom: 13,
                scrollwheel: false
            },
            script,
            $gmaps = $(selector);
        
        function drawMap(gmap, title, options) {
            var map = new google.maps.Map(gmap, options),
                marker = new google.maps.Marker({
                    map: map,
                    position: options.center,
                    title: title,
                    cursor: 'drag',
                    bounds: new google.maps.LatLngBounds()
                });

            function centerMap() {
                map.setCenter(options.center);
            }

            map.setZoom(options.zoom);

            google.maps.event.addDomListener(window, 'resize', function () {
                window.requestAnimationFrame(centerMap);
            });
        }
        
        function drawAddressMap(gmap, title, address, options) {
            new google.maps.Geocoder().geocode({
                'address': address
            }, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    drawMap(gmap, title, $.extend({}, options, {center: results[0].geometry.location}));
                }
            });
        }
        
        function drawPlacesMap(gmap, places, options) {
            var bounds = new google.maps.LatLngBounds(),
                map = new google.maps.Map(gmap, options),
                center,
                marker,
                infowindow,
                i, l = places.length,
                $activeBox;
            
            function centerMap() {
                map.setCenter(bounds.getCenter());
            }
            
            for (i = 0; i < l; i++) {
                center = new google.maps.LatLng(
                    places[i].location.latitude,
                    places[i].location.longitude
                );
                marker = new google.maps.Marker({
                    position: center,
                    map: map,
                    title: places[i].title
                });
                infowindow = new google.maps.InfoWindow({
                    content: (function () {
                        return [
                            places[i].title,
                            '<a href="#' + places[i].boxID + '" data-js-action-smooth-scroll>More info</a>'
                        ].join('<br />');
                    }())
                });
                
                bounds.extend(center);

                google.maps.event.addListener(marker, 'click', (function (boxID, center, marker, infowindow) {
                    return function () {
                        if ($activeBox) {
                            google.maps.event.trigger($activeBox.data('gmaps-infowindow'), 'closeclick');
                        }
                        infowindow.open(map, marker);
                        $activeBox = $('#' + boxID).addClass('is-active').focus();
                    };
                }(places[i].boxID, center, marker, infowindow)));
                
                google.maps.event.addListener(infowindow, 'closeclick', (function (boxID) {
                    return function () {
                        if ($activeBox) {
                            $activeBox.removeClass('is-active').data('gmaps-infowindow').close();
                            $activeBox = null;
                        }
                    }
                }(places[i].boxID)));

                $('#' + places[i].boxID)
                .data('gmaps-infowindow', infowindow)
                .off('click.gmaps.infowindow')
                .on('click.gmaps.infowindow', (function (marker) {
                    return function () {
                        
                        var top = $(gmap).offset().top;
                        
                        google.maps.event.trigger(marker, 'click');
                        
                        if ($(window).scrollTop() > top) {
                            setTimeout(function () {
                                $('html,body').animate({ scrollTop: top }, "fast", "linear")
                            }, 250);
                        }
                    }
                }(marker)))
                .find(':link')
                .off('click.gmaps.infowindow')
                .on('click.gmaps.infowindow', function (event) {
                    event.stopImmediatePropagation();
                });
            }

            if (l > 1) {
                map.fitBounds(bounds);
            } else {
                centerMap();
            }
            
            google.maps.event.addDomListener(window, 'resize', function () {
                window.requestAnimationFrame(centerMap);
            });
        }
        
        function init () {
            var gmap = this,
                data = $(this).data('gmaps');
            
            $(this).addClass("is-active");
            
            switch (true) {
                case !!(data.latitude && data.longitude):
                    drawMap(gmap, data.title, $.extend({}, options, {center: {lat: data.latitude, lng: data.longitude}}));
                    break;
                case !!(data.address):
                    drawAddressMap(gmap, data.title, data.address, options);
                    break;
                case !!(data.places):
                    drawPlacesMap(gmap, data.places, options);
                    break;
            }
        }
        
        
        if ($gmaps.length > 0) {
            
            $(document).on('gmaps-init', selector, init);
            
            CHWEB.gmapsInit = function () {
                $gmaps.trigger('gmaps-init');
            };
            
            script = document.createElement('script');
            script.async = true;
            script.src = '//maps.googleapis.com/maps/api/js?key=' + $gmaps.data('gmaps').key + '&libraries=geometry&callback=CHWEB.gmapsInit';
            
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        }
    });
}(jQuery));