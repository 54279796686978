(function ($) {
    
    var xhr;
    
    function autocomplete () {
        var $this = $(this);
        
        function source (term, suggest) {
            
            function done (response) {
                
                var result = [],
                    l = response.data.length,
                    i;
                
                for (i = 0; i < l; i++) {
                    result.push(response.data[i].attributes.name + ' (' + response.data[i].attributes.code + ')');
                }
                
                suggest(result);
            }
            
            try { xhr.abort(); } catch(e) {}
            
            xhr = $.get($this.data('autocomplete'), { q: term }).done(done);
        }
        
        
        $this.autoComplete({
            source: source,
            menuClass: "form__autocomplete",
            onSelect: function(event, term, item) {
                $this
                .closest('form:not([novalidate])')
                .attr('novalidate', true)
                .delay(10)
                .queue(function (next) {
                    $(this).removeAttr('novalidate');
                    next();
                });
            }
        });
    }
    
    function autocompleteOnSubmit(event) {
        event.stopImmediatePropagation();
        return false;
    }
    
    
    
    $(document)
    .on('submit.autocomplete', 'body:has(.autocomplete-suggestions:visible) form:has([data-autocomplete])', autocompleteOnSubmit);
        
    $(function () {        
        $('[data-autocomplete]').each(autocomplete);
    });
    
}(jQuery));